<template>
  <ParagraphBaseImageVideoBase
    :overline="overline"
    :headline="headline"
    :link="textLink?.uri?.path"
    :is-editing="isEditing"
    :overlay="options.overlay"
    class="paragraph paragraph-image"
  >
    <template #image>
      <MediaImage
        v-blokkli-droppable:field_media_image
        v-bind="image"
        class="h-full w-full"
        img-class="w-full h-full object-cover"
        :image-style="imageStyle"
      />
    </template>

    <template v-if="textLink?.uri?.path" #callToActionText>
      {{ $texts('readMore', 'Read more') }}
    </template>
  </ParagraphBaseImageVideoBase>
</template>

<script lang="ts" setup>
import type { ParagraphImageFragment } from '#graphql-operations'

const { options, isEditing } = defineBlokkli({
  bundle: 'image',
  options: {
    overlay: {
      type: 'checkbox',
      label: 'Overlay',
      default: true,
    },
  },
})

defineProps<ParagraphImageFragment>()

const imageStyle = defineImageStyleOris({
  type: 'scale',
  pictures: {
    sm: {
      widthScale: 1,
      aspectRatio: 9 / 16,
    },
  },
})
</script>
